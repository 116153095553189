
export default {
  name: 'KakaritukeTipsDialog',
  data() {
    return {
      dialog: true
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        //ダイアログが開いた時の処理
      } else {
        //ダイアログが閉じたとき(falseになった時)は$emitを使って親コンポーネントに伝える
        this.$emit('k_close');
      }
    }
  }
};


import SorryDialog from '../Atoms/SorryDialog';
import { BED_DETAIL_ASSET_TYPE } from '~/components/settings/variables';

export default {
  name: 'HospitalDetailDialog',
  components: {
    SorryDialog
  },
  props: {
    visibleItem: { properties: '', bed: '' }
  },
  data() {
    return {
      emptyBedInfoDialog: true,
      hasError: false,
      bedDataList: []
    };
  },
  computed: {
    companyName() {
      return this.visibleItem.properties.company_name || 'company_name';
    },
    tel() {
      return this.visibleItem.bed.tel || '-';
    },
    fax() {
      return this.visibleItem.bed.fax || '-';
    },
    name() {
      return this.visibleItem.bed.name || '-';
    },
    comment() {
      return this.visibleItem.bed.description || 'コメントはありません。';
    },
    lastUpdate() {
      let date = '';
      try {
        date = new Date(this.visibleItem.bed.updateAt.seconds * 1000);
        date = this.$moment(date).format('lll');
      } catch (e) {
        date = '';
      }
      return date || '';
    }
  },
  watch: {
    emptyBedInfoDialog: {
      handler: function (val, oldVal) {
        if (this.emptyBedInfoDialog) {
          this.bedTypes();
        } else {
          this.$emit('close');
        }
      },
      immediate: true //初期表示時に実行させる
    }
  },
  methods: {
    bedTypes: function () {
      const ret = [];
      for (const key in BED_DETAIL_ASSET_TYPE) {
        if (typeof this.visibleItem.bed[key] !== 'undefined') {
          ret.push({
            type: BED_DETAIL_ASSET_TYPE[key],
            man: this.visibleItem.bed[key].man,
            woman: this.visibleItem.bed[key].woman
          });
        }
      }
      this.bedDataList = ret;
    },
    getCssClass: function (val) {
      return Number(val) > 0 ? 'empty' : 'full';
    }
  }
};

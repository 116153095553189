
import getNotify from '../utils/get-notify';
import { mapGetters } from 'vuex';
import BellIcon from '~/static/img/icons/info-bell.svg?inline';

const COOKIE_KEY_NAME = 'notify_timestamp';
export default {
  computed: {
    ...mapGetters({
      analyticsUserId: 'analyticsUserId'
    }),
    notifyColor() {
      return this.hasNotify ? '#F85863' : '#dcdfe6';
    }
  },
  data: function () {
    return {
      isLoading: true,
      hasNotify: false,
      messages: {},
      notifyTimestamp: 0,
      serverNotifyTimestamp: 10
    };
  },
  components: {
    BellIcon
  },
  methods: {
    openNotifyBox: function () {
      this.updateCookie();
    },
    getSheetNo: function (no) {
      return no + 1;
    },
    getMessage: function () {
      getNotify()
        .then((ret) => {
          if (typeof ret.length !== 'undefined') {
            this.messages = ret;
            this.isLoading = false;
            this.checkCookie();
          }
        })
        .catch((e) => {
          this.$sentry.captureException(e);
        });
    },
    checkCookie: function () {
      try {
        const inputMessage = this.messages[0];
        if (this.$cookies.get(COOKIE_KEY_NAME) !== null) {
          this.notifyTimestamp = this.$cookies.get('notify_timestamp');
        }
        if (typeof inputMessage.timestamp !== 'undefined') {
          this.hasNotify = Number(inputMessage.timestamp) > Number(this.notifyTimestamp);
          this.serverNotifyTimestamp = Number(inputMessage.timestamp);
        }
        // console.log(
        //   this.notifyTimestamp,
        //   inputMessage.timestamp,
        //   this.hasNotify
        // );
      } catch (e) {
        this.hasNotify = false;
        // sentry飛ばさない
      }
    },
    updateCookie: function () {
      this.checkCookie();
      this.$cookies.set(COOKIE_KEY_NAME, Number(this.serverNotifyTimestamp));
      this.hasNotify = false;
      this.$analytics({
        // send Google Analytics
        eventCategory: '通知エリアを表示',
        eventAction: '通知エリアを表示',
        eventLabel: `PC, ${this.analyticsUserId}`
      });
    }
  },
  mounted() {
    this.getMessage();
  }
};

module.exports = {
  minZoom: 12, // 広く表示する最大値
  maxZoom: 17,
  defaultZoom: 16,
  searchLastNtimes: 2, // n回で検索制限
  apiKey: 'AIzaSyA8yZoBkfUAk4jByunhd8GSviQC3zczwV4',
  enablePrMode: false, // PR機能の有効化
  searchHitLimit: 50, // mapやリストに表示する上限。広域＆件数多いとブラウザが固まる対策
  adminSearchHitLimit: 200 // 管理者モードでのマーカー表示件数
};

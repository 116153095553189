
export default {
  data() {
    return {
      reloadInfoDialog: true
    };
  },
  methods: {
    updateSW: function () {
      try {
        navigator.serviceWorker.getRegistration().then((registration) => {
          if (typeof registration !== 'undefined') {
            registration.unregister();
            window.location.reload(true);
          } else {
            window.location.reload(true);
          }
        });
      } catch (e) {
        window.location.reload(true);
      }
    }
  }
};


export default {
  data() {
    return {
      errorInfoDialog: true
    };
  },
  props: ['errorinfo'],
  methods: {
    updateSW: function () {
      try {
        this.$analytics({
          // send Google Analytics
          eventCategory: 'エラー',
          eventAction: 'エラー リロードボタンクリック',
          eventLabel: 'エラーコード：' + this.errorinfo
        });
        navigator.serviceWorker.getRegistration().then((registration) => {
          if (typeof registration !== 'undefined') {
            registration.unregister();
            window.location.reload(true);
          } else {
            window.location.reload(true);
          }
        });
      } catch (e) {
        window.location.reload(true);
      }
    }
  },
  mounted: function () {
    try {
      this.$analytics({
        // send Google Analytics
        eventCategory: 'エラー',
        eventAction: 'エラーダイアログ表示',
        eventLabel: 'エラーコード：' + this.errorinfo
      });
    } catch (e) {
      // 何もしなくて良い
    }
  }
};

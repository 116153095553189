/**
 * 施設のマッピング処理
 * コネクト登録していない施設に対して、利用者からコネクト利用として見える必要があるため、登録施設とマッピングする。
 * イレギュラー対応である
 * コネクトやfirestoreのcollectionに例外データや処理を作らないようにするために、ここに集中させている
 * 経緯詳細は以下のURLを参照
 * https://www.notion.so/3sunny/carebookID-abb9abf1dfe846079e3d6b797aeea9da
 */

import { EnableHospitalType } from 'types/GetFirebaseDataTypes';

/**
 * どの施設を対象にマッピングするか
 * 下記の様にcarebookIDを記載する
 * コネクト登録しているcarebookID:[コネクト登録していないcarebookID, ...]
 * 同じcarebookIDを複数記載するパターンは想定していないので注意
 */
const MAPPING_HOSPITAL_LIST: { [key: string]: string[] } = {
  cp0101: ['7740', '7432'],
  cp0103: ['3713', '5780']
  // cpxxxx: ["xxxx", "xxxx"]
};

/**
 * MAPPING_HOSPITAL_LISTに指定したvalueのcarebookIDがある場合、対応するkeyのcarebookIDを返す関数
 * 該当しない場合は、そのままcarebookIDを返す
 */
export function checkMappingFacilityId(facilityId: string) {
  for (const [keyFacilityId, facilityList] of Object.entries(MAPPING_HOSPITAL_LIST)) {
    if (facilityList.includes(facilityId)) {
      return keyFacilityId;
    }
  }
  return facilityId; // 該当しない場合はそのまま返す
}

/**
 * MAPPING_HOSPITAL_LISTに指定したvalueのcarebookIDがある場合、対応するkeyのcarebookIDの施設に対して、コネクトデータを作成する関数
 * cp0101のConnectEnabledHospitalCollectionのデータを使い、"7740", "7432"のデータを作成している
 * 該当しない場合は何もしない
 */
export function makeMappingEnableConnectList(enableConnectList: EnableHospitalType[]) {
  const resultEnableConnectList: EnableHospitalType[] = [];
  for (const keyFacilityId in MAPPING_HOSPITAL_LIST) {
    const filterData = enableConnectList.find((v) => v.id === keyFacilityId);

    if (filterData) {
      MAPPING_HOSPITAL_LIST[keyFacilityId].map((value) => {
        // IDのみを変更すれば良いので、filterDataを展開して、idを変更する
        resultEnableConnectList.push({ ...filterData, id: value });
      });
    }
  }
  return resultEnableConnectList;
}

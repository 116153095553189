import Vue from 'vue';
import VueGtag from 'vue-gtag';

export default async function ({ app: { router } }) {
  //GA4の設定
  Vue.use(
    VueGtag,
    {
      config: {
        id: 'G-4F60642WGK'
      }
    },
    router
  );
}

Vue.prototype.$analytics = function (params) {
  // GA4へ送信
  this.$gtag.event(params.eventAction, {
    eventCategory: params.eventCategory,
    eventLabel: params.eventLabel,
    ...this.$store.getters.analyticsParams
  });
};

import { ASSET_TYPE } from '../components/settings/variables'; //定数
const DEFAULT_STORE_ASSET_TYPE = ASSET_TYPE.hospital;

export const state = () => ({
  currentLocation: {
    // 3sunnyオフィス
    lat: 35.6963951,
    lng: 139.792904
  },
  currentBounds: {
    northEastLatLng: { lat: 139.72655212643124, lng: 139.7471598735688 },
    southWestLatLng: { lat: 35.72601885213774, lng: 35.72931111384344 },
    center: { lat: 35.72601885213774, lng: 139.736856 }
  },
  filterdFeatures: [
    {
      geometry: { type: '', coordinates: [] },
      properties: {
        id: '',
        pref_cd: '',
        update: '',
        company_name: '',
        tel: '',
        一般: '',
        精神: '',
        感染: '',
        結核: '',
        障害: '',
        緩和ケア: '',
        認知症: '',
        回復期リハ: '',
        透析: '',
        地域包括ケア: '',
        医療療養: '',
        介護療養: '',
        url: '',
        都道府県: '',
        住所: ''
      }
    }
  ],
  allfeaturesCount: 0,
  selectedRadio: DEFAULT_STORE_ASSET_TYPE,
  loginStatus: false,
  mapMarkerid: 0,
  initLoading: true,
  analyticsUserId: '',
  myConnectStatus: false, //false コネクト導入ユーザでない
  locationSearch: false, //検索した時のイベント通知用
  searchLimitTimeStamp: 0,
  enableAdminMode: false,
  analytics_params: {
    formatted_address: '',
    locality: '',
    pref: '',
    app_version: '',
    browser_id: '',
    user_id: '',
    user_email: ''
  },
  emptyBedinfoList: [{}]
});

export const mutations = {
  localUpdate(state, currentLocation) {
    //検索した緯度経度へ更新する
    state.currentLocation.lat = currentLocation.lat;
    state.currentLocation.lng = currentLocation.lng;
  },
  boundsUpdate(state, currentBounds) {
    state.currentBounds.northEastLatLng.lat = currentBounds.northEastLatLng.lat();
    state.currentBounds.northEastLatLng.lng = currentBounds.northEastLatLng.lng();
    state.currentBounds.southWestLatLng.lat = currentBounds.southWestLatLng.lat();
    state.currentBounds.southWestLatLng.lng = currentBounds.southWestLatLng.lng();
    state.currentBounds.center.lat = currentBounds.center.lat();
    state.currentBounds.center.lng = currentBounds.center.lng();
  },
  dataUpdate(state, resultData) {
    state.filterdFeatures = Object.assign([], resultData);
  },
  allfeaturesCount(state, status) {
    state.allfeaturesCount = status;
  },
  selectedRadio(state, event) {
    state.selectedRadio = event;
  },
  loginStatus(state, status) {
    //firebaseログインtruefalse
    state.loginStatus = status;
  },
  mapMarkerid(state, status) {
    state.mapMarkerid = status;
  },
  initLoading(state, status) {
    //初期表示ローディング
    state.initLoading = status;
  },
  updateAnalyticsUserId(state, status) {
    state.analyticsUserId = status;
  },
  myConnectStatus(state, status) {
    state.myConnectStatus = status;
  },
  locationSearch(state, status) {
    state.locationSearch = status;
  },
  searchLimitTimeStamp(state, status) {
    state.searchLimitTimeStamp = status;
  },
  enableAdminMode(state, status) {
    state.enableAdminMode = status;
  },
  setAnalyticsParams(state, params) {
    state.analytics_params = Object.assign(state.analytics_params, params);
  },
  emptyBedinfoList(state, resultData) {
    state.emptyBedinfoList = Object.assign([], resultData);
  }
};

export const getters = {
  location(state) {
    return state.currentLocation;
  },
  bounds(state) {
    return state.currentBounds;
  },
  features(state) {
    return state.filterdFeatures;
  },
  allFeatures(state) {
    return state.allfeaturesCount;
  },
  radios(state) {
    return state.selectedRadio;
  },
  loginStatus(state) {
    return state.loginStatus;
  },
  mapMarker(state) {
    return state.mapMarkerid;
  },
  loadingStatus(state) {
    return state.initLoading;
  },
  analyticsUserId(state) {
    return state.analyticsUserId;
  },
  connectStatus(state) {
    return state.myConnectStatus;
  },
  searchMap(state) {
    return state.locationSearch;
  },
  searchLimitTime(state) {
    return state.searchLimitTimeStamp;
  },
  adminMode(state) {
    return state.enableAdminMode;
  },
  analyticsParams(state) {
    return state.analytics_params;
  },
  emptyBedinfo(state) {
    return state.emptyBedinfoList;
  }
};

import { ST_ERROR } from '../settings/errorcode';

export const useWorker = () => {
  type MessageData = {
    State: string;
    Topic: string;
    Json: string;
  };

  // TODO : 本当はErrorにも以下のような型をつけたい。Vue側がTypeScriptで書かれていないため今はできない
  // type ErrorReason = {
  //   case: string
  //   code: number // UI表示用に使うコード
  //   detail: string
  //   data: MessageData
  // }

  const workerResultHandling = (
    event: MessageEvent<MessageData>,
    resolve: (value?: unknown) => void,
    reject: (reason?: any) => void
  ) => {
    switch (event.data.State) {
      case 'loadDatasComplete':
      case 'unbz2success':
        resolve(event);
        break;
      case 'unbz2Error': // ファイルの展開が失敗している場合
        reject({
          case: 'fileDecompressionHasFailed',
          code: ST_ERROR._60.code,
          detail: ST_ERROR._60.detail,
          data: event.data
        });
        break;
      case 'noGetData':
        reject({
          case: 'noDataFetched',
          code: ST_ERROR._70.code,
          detail: ST_ERROR._70.detail,
          data: event.data
        });
        break;
      case 'timeout':
        reject({
          case: 'timeout',
          code: undefined,
          detail: ST_ERROR._20.detail,
          data: event.data
        });
        break;
      // 以下、その他のエラー
      case 'DataIndexError':
      case 'connectionDatasError':
      case 'setCacheDataCatchError':
      case 'setUpTasksCatchError':
      case 'initTasksCatchError':
        reject({
          case: 'runtimeError',
          code: undefined,
          detail: ST_ERROR._80.detail,
          data: event.data
        });
        break;
      default:
        // エラー以外の場合は何もしない
        resolve();
        break;
    }
  };

  return {
    workerResultHandling
  };
};

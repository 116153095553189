
import { mapGetters } from 'vuex';
import Bowser from 'bowser';
import { v1 as uuidv1 } from 'uuid';
import { onAuthStateChanged } from 'firebase/auth';
import { ASSET_TYPE, BUILD_TYPE_MAP, CONNECT_MODE_MAP } from '../settings/variables'; // 定数 //定数
import { ST_ERROR } from '../settings/errorcode'; // エラー通知用の定数
import { makeMappingEnableConnectList } from '../utils/facilityMapping';
import Worker from '~/assets/scripts/worker.js';
import SearchToolbar from '~/components/molecules/SearchToolbar.vue';
import HospitalStationCard from '~/components/molecules/HospitalStationCard.vue';
import RequestDialog from '~/components/Atoms/LoginRequestDialog';
import NotifyBell from '~/components/Atoms/NotifyBellOfficialOnly';
import KakaritukeDialog from '~/components/Atoms/KakaritukeTipsDialog';
import ShienshinDialog from '~/components/Atoms/ShienshinTipsDialog';
import DetailsButton from '~/components/Atoms/DetailsButton';
import ReloadinfoDialog from '~/components/Atoms/ReloadInfoDialog';
import ErrorinfoDialog from '~/components/Atoms/ErrorInfoDialog';
import getEnableHospitalList from '../utils/get-enable-hospital-list';
import LoadingLogo from '../Atoms/LodingLogo';
import { auth } from '../settings/firebase';
import { Loader } from '@googlemaps/js-api-loader';
import { useGeoData } from '~/components/hooks/useGeoData';
import { useWorker } from '~/components/hooks/useWorker';

// vuexのstoreにcommitすると激おそ(古いブラウザだとcommitだけで6秒以上かかる)なので、生で扱う
let houkanGeoData = {
  features: {
    properties: {},
    geometry: {}
  }
};
let hospitalGeoData; // 同上
let clinicGeoData; // 同上
let areaGeoData; // 同上
let roukenGeoData; // 同上
let kakaritukeGeoData; // 同上
let tokuyouGeoData; // 同上
let kaigoiryouinGeoData; // 同上

const mapSettings = require('../settings/map');

const SEARCH_HIT_LIMIT = mapSettings.searchHitLimit;
const ADMIN_SEARCH_HIT_LIMIT = mapSettings.adminSearchHitLimit;
const SEARCH_LAST_N_TIME = mapSettings.searchLastNtimes; // この回数で利用制限される
const SEARCH_RELOAD_LIMIT = 7; // リロードさせるダイアログを出す日数
const DEFAULT_IP_ADDRESS = '1.1.1.1'; // firebaseに設定しているデフォルトのIPアドレス値

const { getGeoDataType, GEO_DATA_TARGET, DEFAULT_ASSET_TYPE, PROGRESS_COMPLETE, PROGRESS_PER } =
  useGeoData();

const { workerResultHandling } = useWorker();

export default {
  name: 'serchToolbar',
  components: {
    SearchToolbar,
    HospitalStationCard,
    RequestDialog,
    KakaritukeDialog,
    ShienshinDialog,
    NotifyBell,
    DetailsButton,
    LoadingLogo,
    ReloadinfoDialog,
    ErrorinfoDialog
  },
  data() {
    return {
      google: null,
      currentLocation: this.$store.getters.location,
      progress: 0,
      loading: true,
      failedLoadAssets: [], // 初期化に失敗したデータ情報
      disabledSearch: false, // 検索inputの無効化
      firstView: false, // 初期表示
      radio: DEFAULT_ASSET_TYPE, // ラジオボタンの表示制御用
      tabletClassType: '', // カテゴリボタンのtablet表示用
      isLoggedInUser: false, // firebaseログイン済ユーザーのときはtrue
      requestdialog: false, // リクエストログイン表示
      kakaritukeDialog: false, // かかりつけダイアログ表示制御
      shienshinDialog: false, // 訪診ダイアログ表示制御
      reloaddialog: false, // リロードを促すダイアログ
      errordialog: false, // エラーを知らせるダイアログ
      errorCode: '', // ユーザに知らせるエラーコード
      filteredResultCount: 0, // 該当なし表示のための全国のフィルター後の施設数
      lastNtimes: SEARCH_LAST_N_TIME, // 未ログインユーザーの検索回数残
      alartNotification: {}, // 未ログインユーザ
      enableConnectList: [], // Connect導入済みの病院一覧
      resultEnableConnectList: [], // 施設毎のConnect導入済みの施設一覧
      resultConnectModeList: [], // コネクトのconnect_modeの一覧
      loginId: '', // SearchのログインID Connect導入確認のため必要
      traialAlart: false,
      applyVersion: '',
      vesionSnackbar: false,
      browserCheck: true, // 検索してから放置されているかの判定用。falseの時はreloaddialogを出し、geoCodingを行わない
      enableAdminMode: false, // 管理者限定の機能ONOFF
      searchEmptyResult: false, // googlemapApi responceのerrorの場合true
      workerProcessCheck: false, // エラーログ監視のために設置 worker.jsの処理が成功したかどうか
      /////////////////////////////////
      // フィルターラベルの制御用
      errMsg: '', // エラー通知用
      // 訪看
      isPTOT: false, // PTOT在籍
      isST: false, // ST在籍
      // 病院
      isGeneral: false, // 一般
      isInfection: false, // 感染
      isPsychopathic: false, // 精神
      isTuberculosis: false, // 結核
      isKaigo: false, // 介護療養
      isIryou: false, // 医療療養
      isKanwa: false, // 緩和ケア
      isNinchi: false, // 認知症
      isRiha: false, // 回復期リハ
      isArea: false, // 地域包括ケア
      isTouseki: false, // 透析
      isHandicapped: false, // 障がい
      // 訪問医療
      // isMitori: false, // 看取り30以上
      // isBed: false, // 病床あり
      // かかりつけ医
      isKakarituke: false, // かかりつけ医
      isChild: false, // 小児かかりつけ医
      isHome: false, // 支援診
      // 老健
      isUnitSingle: false, // ユニット型個室
      isUnitOldtype: false, // 従来型個室
      isMulti: false, // 多床室
      isZaitaku: false, // 在宅強化型
      isRoukenPt: false, // PT
      isRoukenSt: false, // ST
      isRoukenOt: false // OT
      // ここまで
      /////////////////////////////////
    };
  },
  watch: {
    progress: function () {
      if (this.progress >= PROGRESS_COMPLETE) {
        this.loadCompleted();
      }
    },
    currentBounds: {
      // data/currentBoundsの変更を検知
      handler: function (val, oldVal) {
        // 初期表示の時は実行させずに、それ以降の操作の時のみ実行させる
        if (this.firstView !== false) {
          this.filterData();
        }
      },
      deep: true
    },
    boundsStation: {
      handler: function (val2, oldVal2) {
        this.trialAlart();
      },
      deep: true
    },
    selectedRadio: {
      handler: function (val3, oldVal3) {
        // コネクト利用施設のマーカーなどの表示の切り替えを行う
        this.enableFacilityList();
        // ラジオボタンが切り替わった時にマーカーの再描画を行う
        this.filterData();
      },
      deep: true
    }
  },
  methods: {
    updateSW: async function () {
      try {
        const registration = await navigator.serviceWorker.getRegistration();
        if (registration && registration.unregister) {
          // service workerの登録を解除
          registration.unregister();
        }

        // Cache Strageの一覧を取得する
        const cachesTargets = await caches.keys();
        if (cachesTargets) {
          // Cache Strageを削除する
          cachesTargets.map((cachesName) => caches.delete(cachesName));
        }
        // LocalStrage chousei-kunを消す
        localStorage.removeItem('chousei-kun');

        // リロードする
        window.location.reload(true);
      } catch (error) {
        // UI表示のエラーコード指定
        this.errorCode = ST_ERROR._90.code;
        // エラーダイアログを出す
        this.errordialog = true;
        // sentryにエラー詳細情報送る
        this.sendSentry(ST_ERROR._90.detail, error);
      }
    },
    snackbar: function () {
      this.vesionSnackbar = true;
    },
    snackbarLeave: function () {
      this.vesionSnackbar = false;
    },
    loadCompleted: function () {
      setTimeout(() => {
        // ログインしていない場合はダイアログ表示する
        this.openLoginRequestDialog();

        // ステータス設定等完了した状態で、再度描画する
        this.filterData();

        // ローディング画面を消して、vuexに通知
        this.$store.commit('initLoading', (this.loading = false));
      }, 2000);
    },
    /**
     * 事業所データのDL＆展開
     */
    loadGeoData: function () {
      /**
       *  Web Worker(別スレッド)側で住所データを取りに行く
       */
      const setData = (data) => {
        // Vuexのstoreに格納すると激おそになるので(100倍以上おそくなる)js直で管理
        switch (getGeoDataType(data.Topic)) {
          case ASSET_TYPE.houkan:
            houkanGeoData = data.Json;
            this.progress += PROGRESS_PER;
            break;
          case ASSET_TYPE.hospital:
            hospitalGeoData = data.Json;
            this.progress += PROGRESS_PER;
            break;
          case ASSET_TYPE.clinic:
            clinicGeoData = data.Json;
            this.progress += PROGRESS_PER;
            break;
          case ASSET_TYPE.area:
            areaGeoData = data.Json;
            this.progress += PROGRESS_PER;
            break;
          case ASSET_TYPE.rouken:
            roukenGeoData = data.Json;
            this.progress += PROGRESS_PER;
            break;
          case ASSET_TYPE.kakarituke:
            kakaritukeGeoData = data.Json;
            this.progress += PROGRESS_PER;
            break;
          case ASSET_TYPE.tokuyou:
            tokuyouGeoData = data.Json;
            this.progress += PROGRESS_PER;
            break;
          case ASSET_TYPE.kaigoiryouin:
            kaigoiryouinGeoData = data.Json;
            this.progress += PROGRESS_PER;
            break;
        }
      };

      /**
       * 並列実行
       */
      const startMs = new Date().getTime();
      const MAX_WORKER = Object.keys(GEO_DATA_TARGET).length;
      const tasks = [];
      for (let i = 0; i < MAX_WORKER; i++) {
        const worker = new Worker(); // eslint-disable-line
        // Note: typescriptで定義してお型付けをしたい。Promise<ResultType>のような
        const promise = new Promise((resolve, reject) => {
          worker.addEventListener(
            'message',
            (event) => workerResultHandling(event, resolve, reject),
            { capture: false, passive: true }
          );
        });
        tasks.push(promise);
        worker.postMessage([GEO_DATA_TARGET[i]]);
      }
      Promise.allSettled(tasks)
        .then((result) => {
          result.forEach((result) => {
            if (result.status === 'fulfilled') {
              setData(result.value.data);
              this.workerProcessCheck = true;
            } else {
              this.errorCode = result.reason?.code ?? '';
              this.sendSentry(
                result.reason.case + ' : ' + result.reason.detail,
                result.reason.data
              );
              this.$sentry.captureException(result.reason);
              // 一次対応： 何らかの理由でデータが取得できなかった場合は、その情報以外で利用できるようにする。
              // ただし病院の場合はメインの機能なので表示しない
              if (getGeoDataType(result.reason.data.Topic) !== ASSET_TYPE.hospital) {
                this.progress += PROGRESS_PER;
              }
              this.failedLoadAssets.push(getGeoDataType(result.reason.data.Topic));
            }
          });
          const bowser = Bowser.getParser(window.navigator.userAgent);
          const elapsedMs = new Date().getTime() - startMs;
          // GA4
          this.$gtag.time({
            timingCategory: 'GeoData Dependencies',
            timingVar: 'load',
            timingValue: elapsedMs,
            timingLabel: bowser.getBrowserName()
          });
          // console.log(`${elapsedMs} ms`);
          // この時点で一回描画させる
          (this.firstView = true), this.filterData();
        })
        .catch((error) => {
          this.$sentry.captureException(error);
        });
    },
    autoCompleteSearch: function () {
      let map;
      if (!this.disabledSearch) {
        // 住所検索のオートコンプリートの設定
        // https://developers.google.com/maps/documentation/javascript/places-autocomplete?hl=ja
        new this.google.maps.places.Autocomplete(document.getElementById('autocomplete'), {
          types: ['(regions)'],
          componentRestrictions: { country: 'jp' }
        });

        // 虫めがねの検索ボタン押下時イベント
        document.getElementById('submit').addEventListener(
          'click',
          () => {
            // ブラウザを立ち上げっぱなしにしているか確認
            this.browserCheck = this.browserSessionCheck();

            if (this.browserSessionCheck) {
              this.geocodeAddress(map);
            } else {
              // リロードを促すダイアログを表示させる
              this.reloaddialog = true;
              // 検索不可にする
              this.disableSearchField();
            }
          },
          { passive: true }
        );

        // 住所検索inputのEnter押下時イベント
        document.getElementById('autocomplete').addEventListener(
          'keypress',
          (e) => {
            // Enter Keyはコードが13
            if (e.keyCode === 13) {
              // ブラウザを立ち上げっぱなしにしているか確認
              this.browserCheck = this.browserSessionCheck();

              if (this.browserCheck) {
                this.geocodeAddress(map);
              } else {
                // リロードを促すダイアログを表示させる
                this.reloaddialog = true;

                // 検索不可にする
                this.disableSearchField();
              }
            }
          },
          { passive: true }
        );
      }
    },
    /**
     *  住所ジオコーディング
     */
    geocodeAddress: async function (resultMap) {
      try {
        // 住所のジオコーディングの設定
        const geocoder = new this.google.maps.Geocoder();

        let address = document.getElementById('autocomplete').value;

        // 何も入力していない場合など、空白は取り除く(APIの送信防止)
        address = address.trim();
        if (address.length) {
          const responce = await geocoder.geocode({ address: address });
          const results = responce.results;

          if (results !== null && this.currentLocation !== results[0].geometry.location) {
            this.currentLocation = results[0].geometry.location;

            // Vuexに地図の中心点を保存する
            let currentLocation = {
              lat: this.currentLocation.lat(),
              lng: this.currentLocation.lng()
            };
            this.$store.commit('localUpdate', currentLocation);

            // 検索イベントを通知（検索はロケーション値でしない）
            let searchEvent = true;
            this.$store.commit('locationSearch', searchEvent);

            let pref = '';
            let locality = '';
            try {
              pref = results[0].address_components.filter((component) => {
                return component.types.includes('administrative_area_level_1');
              });
              pref = pref[0].long_name;
              locality = results[0].address_components.filter((component) => {
                return component.types.includes('locality');
              });
              locality = locality[0].long_name;
            } catch (e) {
              // なにもしない。sentryにも送らない
            }
            // GA4で取得するデータをstoreに格納する
            this.$store.commit('setAnalyticsParams', {
              formatted_address: results[0].formatted_address,
              pref: pref,
              locality: locality
            });
            this.$analytics({
              // send Google Analytics
              eventCategory: '検索ボタン押下',
              eventAction: address,
              eventLabel: this.makeAnalyticsLabel()
            });
            this.saveSearchCount();
            this.showNotify();
          }
        }
      } catch (e) {
        // 検索結果にヒットしなかった場合や検索に失敗している場合
        this.searchEmptyResult = true;
        // 検索結果がないことを表示する
        setTimeout(() => {
          this.searchEmptyResult = false;
        }, 3000);
      }
    },
    /**
     *  作成：施設データの絞り込み
     */
    filterData: function () {
      let boundsStation;
      /**
       *  [重要]
       *  事前に読み込んだ事業所データを表示エリアで絞り込む
       *  描画するマーカ−を切り替えるために、フラグをみている
       */
      let viewData;
      switch (this.radio) {
        case ASSET_TYPE.hospital:
          viewData = hospitalGeoData;
          break;
        case ASSET_TYPE.houkan:
          viewData = houkanGeoData;
          break;
        case ASSET_TYPE.clinic:
          viewData = clinicGeoData;
          break;
        case ASSET_TYPE.area:
          viewData = areaGeoData;
          break;
        case ASSET_TYPE.rouken:
          viewData = roukenGeoData;
          break;
        case ASSET_TYPE.kakarituke:
          viewData = kakaritukeGeoData;
          break;
        case ASSET_TYPE.tokuyou:
          viewData = tokuyouGeoData;
          break;
        case ASSET_TYPE.kaigoiryouin:
          viewData = kaigoiryouinGeoData;
          break;
        default:
          viewData = houkanGeoData;
      }
      // 地図の表示範囲を取得
      try {
        if (
          Array.isArray(viewData.features) &&
          viewData.features.length // 配列であることかつ、featuresが格納されている
        ) {
          // フィルターの条件によって表示対象を変更する
          try {
            boundsStation = Object.assign({}, viewData);
            boundsStation.features = boundsStation.features.filter((feature) => {
              // 表示対象にするか否かのフラグ。AND条件での絞込み
              // 処理の最後までtrueだったらそれは表示対象となる
              let isEnable = true;
              /**
               * 施設タイプごとのフィルター処理
               */
              if (this.radio === ASSET_TYPE.hospital) {
                // 一般のフィルタ有無
                if (this.isGeneral) {
                  isEnable = isEnable && feature.properties['一般'] === 'あり';
                }
                // 感染のフィルタ有無
                if (this.isInfection) {
                  isEnable = isEnable && feature.properties['感染'] === 'あり';
                }
                // 精神のフィルタ有無
                if (this.isPsychopathic) {
                  isEnable = isEnable && feature.properties['精神'] === 'あり';
                }
                // 結核のフィルタ有無
                if (this.isTuberculosis) {
                  isEnable = isEnable && feature.properties['結核'] === 'あり';
                }
                // 介護のフィルタ有無
                if (this.isKaigo) {
                  isEnable = isEnable && feature.properties['介護療養'] === 'あり';
                }
                // 介護療養のフィルタ有無
                if (this.isIryou) {
                  isEnable = isEnable && feature.properties['医療療養'] === 'あり';
                }
                // 緩和ケアのフィルタ有無
                if (this.isKanwa) {
                  isEnable = isEnable && feature.properties['緩和ケア'] === 'あり';
                }
                // 認知症のフィルタ有無
                if (this.isNinchi) {
                  isEnable = isEnable && feature.properties['認知症'] === 'あり';
                }
                // 回復期リハのフィルタ有無
                if (this.isRiha) {
                  isEnable = isEnable && feature.properties['回復期リハ'] === 'あり';
                }
                // 地域包括ケアのフィルタ有無
                if (this.isArea) {
                  isEnable = isEnable && feature.properties['地域包括ケア'] === 'あり';
                }
                // 透析液水質確保のフィルタ有無
                if (this.isTouseki) {
                  isEnable = isEnable && feature.properties['透析'] === 'あり';
                }
                if (this.isHandicapped) {
                  isEnable = isEnable && feature.properties['障害'] === 'あり';
                }
              }
              if (this.radio === ASSET_TYPE.houkan) {
                // 訪看データのフィルタ
                // PTOT在籍のフィルタ有無
                if (this.isPTOT) {
                  isEnable =
                    isEnable && (feature.properties['PT'] > 0 || feature.properties['OT'] > 0);
                }
                // ST在籍のフィルタ有無
                if (this.isST) {
                  isEnable = isEnable && feature.properties['ST'] > 0;
                }
              }
              if (this.radio === ASSET_TYPE.clinic) {
                // 訪問診療データのフィルタ
                // if (this.isMitori) {
                //   isEnable =
                //     isEnable && feature.properties["看取り件数(総数)"] >= 30;
                // }
                // if (this.isBed) {
                //   isEnable = isEnable && feature.properties["診入院"] === "あり";
                // }
              }
              if (this.radio === ASSET_TYPE.kakarituke) {
                // かかりつけ医のフィルタ
                if (this.isKakarituke) {
                  isEnable = isEnable && feature.properties['地包加'] === 'あり';
                }
                if (this.isChild) {
                  isEnable = isEnable && feature.properties['小か診'] === 'あり';
                }
                if (this.isHome) {
                  isEnable = isEnable && feature.properties['支援診'] === 'あり';
                }
              }
              if (this.radio === ASSET_TYPE.rouken) {
                // ユニット型個室
                if (this.isUnitSingle) {
                  isEnable = isEnable && feature.properties['unit_single'] === 'あり';
                }
                // 従来型個室
                if (this.isUnitOldtype) {
                  isEnable = isEnable && feature.properties['unit_oldtype'] === 'あり';
                }
                // 多床室
                if (this.isMulti) {
                  isEnable = isEnable && feature.properties['multi'] === 'あり';
                }
                // 在宅強化型
                if (this.isZaitaku) {
                  isEnable = isEnable && feature.properties['zaitaku'] === 'あり';
                }
                // PT
                if (this.isRoukenPt) {
                  isEnable = isEnable && feature.properties['staff_pt'] > 0;
                }
                // ST
                if (this.isRoukenSt) {
                  isEnable = isEnable && feature.properties['staff_st'] > 0;
                }
                // OT
                if (this.isRoukenOt) {
                  isEnable = isEnable && feature.properties['staff_ot'] > 0;
                }
              }
              return isEnable;
            });
          } catch {
            // UI表示のエラーコード指定
            this.errorCode = ST_ERROR._10.code;
            // sentryにエラー詳細情報送る
            this.sendSentry(ST_ERROR._10.detail, this.loadingErrorData);
          }

          // 該当の全施設数を保持。地図上で該当ないとき
          if (boundsStation.features.length) {
            this.filteredResultCount = boundsStation.features.length;
            // 上記全施設数をVuexへ保存
            this.$store.commit('allfeaturesCount', this.filteredResultCount);
          }

          // 表示エリアに対象施設を絞り込む
          boundsStation = this.filterBounds(boundsStation);

          // 中心に近い順にソート(distの昇順)
          boundsStation.features.sort(function (a, b) {
            return a.dist - b.dist;
          });
          boundsStation = boundsStation.features;

          // console.log("コネクト導入の施設一覧", this.resultConnectModeList);

          // 画面内にある施設に対して1施設ずつ処理
          for (const item of boundsStation) {
            // コネクト導入に該当する施設IDがある場合は値を入れる
            const connectHit = this.resultConnectModeList.find((v) => v.id === item.properties.id);
            // 該当の施設IDにコネクトモードが設定されている場合は、コネクト導入施設として扱う
            if (connectHit && connectHit.connect_mode && connectHit.conncect_build_types) {
              // コネクトモードを追加
              item.properties['connect_mode'] = connectHit.connect_mode;
              item.properties['conncect_build_types'] = connectHit.conncect_build_types;
            }
          }
          this.updateFeatures(boundsStation);
        }
      } catch (e) {
        // UI表示のエラーコード指定
        this.errorCode = this.errorCode ? this.errorCode : ST_ERROR._50.code;
        // エラーダイアログを出す
        this.errordialog = true;
        // sentryにエラー詳細情報送る
        this.sendSentry(ST_ERROR._50.detail, this.loadingErrorData);
      }
    },
    filterBounds: function (targetData) {
      // 何らかの原因でデータが配列でない時は処理しない
      if (!Array.isArray(targetData.features)) {
        // UI表示のエラーコード指定
        this.errorCode = ST_ERROR._40.code;
        // エラーダイアログを出す
        this.errordialog = true;
        // sentryにエラー詳細情報送る
        this.sendSentry(ST_ERROR._40.detail, this.loadingErrorData);
      }

      // 地図の表示範囲を取得
      try {
        let northEastLatLng = this.currentBounds.northEastLatLng; // 左上
        // noinspection JSUnresolvedFunction
        let southWestLatLng = this.currentBounds.southWestLatLng; // 右下

        // 表示範囲を若干内側にする(境界にマーカーが来ても視認できないため)
        const MARGIN_LENGTH = 0.001;
        let neLat = northEastLatLng.lat - MARGIN_LENGTH;
        let neLng = northEastLatLng.lng - MARGIN_LENGTH;
        let swLat = southWestLatLng.lat + MARGIN_LENGTH;
        let swLng = southWestLatLng.lng + MARGIN_LENGTH;

        let maplat = this.currentBounds.center.lat;
        let maplng = this.currentBounds.center.lng;

        let geoData = Object.assign({}, targetData); // copy geoData object

        geoData.features = geoData.features.filter((val) => {
          let lat, lng;
          try {
            // 緯度経度が無いデータが有ったときはskipする
            lat = val.geometry.coordinates[1]; // 緯度
            lng = val.geometry.coordinates[0]; // 経度
          } catch (e) {
            // skip
            // console.log(JSON.stringify(val));
          }
          // 左上と右下を基準とした、四角形の範囲に含まれるか判定
          // 範囲内のものだけ描画対象にする
          if (lat < neLat && lat > swLat && lng < neLng && lng > swLng) {
            // 中心からの距離を計算する
            val['dist'] = this.googleDistance(lat, lng, maplat, maplng);
            return val;
          }
        });
        if (this.isSearchHitlimit <= geoData.features.length) {
          geoData.features.sort(function (a, b) {
            return a.dist - b.dist;
          });
          geoData.features = geoData.features.slice(0, this.isSearchHitlimit);
        }

        return geoData;
      } catch (e) {
        this.$sentry.captureException(e);
        return false;
      }
    },
    /**
     * 作成：表示エリアの施設データをvuexへ保存
     */
    updateFeatures: function (resultData) {
      const cloneDeep = require('lodash/cloneDeep');
      // 絞り込んだ施設データをVuexへ
      resultData = cloneDeep(resultData);
      this.$store.commit('dataUpdate', resultData);
    },
    /**
     * 2地点間の距離を求める
     *   GoogleMapAPIのgeometory.computeDistanceBetweenのロジック
     *   浮動小数点の精度が足りないためGoogleより桁数が少ないかもしれません
     * https://qiita.com/chiyoyo/items/b10bd3864f3ce5c56291
     * @param lat1 緯度１
     * @param lon1 経度１
     * @param lat2 緯度２
     * @param lon2 経度２
     * @return number 距離(m)
     */
    googleDistance: function (lat1, lon1, lat2, lon2) {
      // 緯度経度をラジアンに変換
      let radLat1 = this.deg2rad(lat1); // 緯度１
      let radLon1 = this.deg2rad(lon1); // 経度１
      let radLat2 = this.deg2rad(lat2); // 緯度２
      let radLon2 = this.deg2rad(lon2); // 経度２

      let r = 6378137.0; // 赤道半径

      let averageLat = (radLat1 - radLat2) / 2;
      let averageLon = (radLon1 - radLon2) / 2;
      return (
        r *
        2 *
        Math.asin(
          Math.sqrt(
            Math.pow(Math.sin(averageLat), 2) +
              Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(averageLon), 2)
          )
        )
      );
    },
    // 度とラジアンの相互変換
    deg2rad: function (d) {
      return (d / 180) * Math.PI;
    },
    changeSearchFilter: function (event) {
      // console.log("changeSearchFilter", event);
      // ラジオボタンが切り替わった時にマーカーの再描画を行う
      this.filterData();
      // 絞り込んだ状態でマップを再描画させるためstoreに保存
      // this.$store.commit("selectedRadio", this.selectedRadio);
      // GAにイベントとして送る対象のフィルター列挙
      const targetFilter = {
        // 訪問看護
        isPTOT: 'PTOT在籍',
        isST: 'ST在籍',
        // 病院
        isGeneral: '一般病床',
        isInfection: '感染病床',
        isPsychopathic: '精神病床',
        isTuberculosis: '結核病床',
        isKaigo: '介護療養',
        isIryou: '医療療養',
        isKanwa: '緩和ケア',
        isNinchi: '認知症',
        isRiha: '回復期リハ',
        isArea: '地域包括ケア',
        isTouseki: '透析',
        // 訪問医療
        // isMitori: "看取り件数30以上",
        // isBed: "病床あり",
        // かかりつけ医
        isKakarituke: '(近医)かかりつけ医',
        isChild: '(近医)小児かかりつけ医',
        isHome: '(近医)在宅',
        // 老人ホーム
        isKaigoYuryou: '介護付有料老人ホーム',
        isKaigoSakou: '介護付サ高住',
        isKaigoKeihi: '介護付ケアハウス'
      };

      let filter = '';
      for (let key in targetFilter) {
        filter += this.$data[key] ? `${targetFilter[key]}, ` : '';
      }

      // フィルタONにしたときにGA送信
      if (event) {
        this.$analytics({
          // send Google Analytics
          eventCategory: 'フィルタで絞込',
          eventAction: filter.replace(/, $/, ''),
          eventLabel: this.makeAnalyticsLabel()
        });
        this.$analytics({
          // send Google Analytics
          eventCategory: 'フィルタがONな項目',
          eventAction: event,
          eventLabel: this.makeAnalyticsLabel()
        });
      }
    },
    changeSearchType: function (event) {
      try {
        this.$store.commit('selectedRadio', event);
      } catch (e) {
        // なにもしない。sentryにも通知しない
      }
      if (event) {
        this.$analytics({
          // send Google Analytics
          eventCategory: 'カテゴリを表示',
          eventAction: event,
          eventLabel: this.makeAnalyticsLabel()
        });
      }
    },
    openLoginDialog: function () {
      window.location.href = '/login';
    },
    openLoginRequestDialog: function () {
      if (this.isLoggedInUser !== true) {
        // ログインしていない場合はダイアログ表示する
        this.requestdialog = true;
      }
    },
    platformCheck: function () {
      // Borserでプラットフォーム判定
      const bowser = Bowser.getParser(window.navigator.userAgent);
      let isPlatform = bowser.getPlatform();

      if (isPlatform?.type === 'mobile') {
        // スマートフォン専用の案内ページに遷移する
        try {
          this.$router.push('/smartphone');
        } catch (error) {
          // 何もしない
        }
      }
    },
    logoutDialog: function () {
      // const hostname = window.location.hostname;
      // if (
      //   hostname === 'localhost' ||
      //   hostname === 'stg-search.carebook.jp' ||
      //   /192\.168\..*/.test(hostname)
      // ) {
      //   // noinspection JSUnresolvedFunction
      //   this.$firestore
      //     .auth()
      //     .signOut()
      //     .then(() => {
      //       // Sign-out successful.
      //       this.$data.isSigned = false;
      //       this.$store.commit('updateAnalyticsUserId', '');
      //     })
      //     .catch((error) => {
      //       // An error happened.
      //       console.log(error);
      //     });
      // }
    },
    openKakaritukeTipsDialog: function () {
      this.kakaritukeDialog = true; // ダイアログ表示
      this.$analytics({
        // send Google Analytics
        eventCategory: 'ヘルプ文言(かかりつけ医)を表示',
        eventAction: 'クリック',
        eventLabel: this.makeAnalyticsLabel()
      });
    },
    closeKakaritukeTipsDialog: function () {
      this.kakaritukeDialog = false;
    },
    openShienshinTipsDialog: function () {
      this.shienshinDialog = true; // ダイアログ表示
      this.$analytics({
        // send Google Analytics
        eventCategory: 'ヘルプ文言(訪問診療)を表示',
        eventAction: 'クリック',
        eventLabel: this.makeAnalyticsLabel()
      });
    },
    closeShienshinTipsDialog: function () {
      this.shienshinDialog = false;
    },
    showNotify: function () {
      if (!this.isLoggedInUser) {
        // No user is signed in.
        const notify = {
          title: '',
          message: ''
        };

        if (this.lastNtimes > 0) {
          notify.title = `あと${this.lastNtimes || 0}回`;
          notify.message = `
ログインせずに利用する場合<br>検索回数に制限があります<br>
新規利用のお申込みは<br>
お問い合わせフォームにてご連絡下さい<br>
<a href="https://docs.google.com/forms/d/e/1FAIpQLSfvCLGNLmfNaCDfqQ9MKuSjOwNkBoM8nOB4t6lNe_KEgyQrag/viewform?usp=sf_link" target="_blank" rel="noopener" class="square_btn">フォームはこちら</a>`;
          this.enableSearchField();
        } else {
          notify.title = '試用期間が終了しました';
          notify.message = `
ログインしましょう！<br>
ログイン情報が不明な場合や<br>
新規利用のお申込みは<br>
お問い合わせフォームにてご連絡下さい<br>
<a href="https://docs.google.com/forms/d/e/1FAIpQLSfvCLGNLmfNaCDfqQ9MKuSjOwNkBoM8nOB4t6lNe_KEgyQrag/viewform?usp=sf_link" target="_blank" rel="noopener" class="square_btn">フォームはこちら</a>
`;
          this.disableSearchField();
        }

        this.alartNotification = {
          title: notify.title,
          message: notify.message
        };
        this.traialAlart = true;
      }
    },
    trialAlart: function () {
      if (this.boundsStation.length >= SEARCH_HIT_LIMIT) {
        this.traialAlart = false;
      }
    },
    saveSearchCount: function () {
      if (this.isLoggedInUser !== true) {
        // No user is signed in.
        let count = this.lastNtimes > 0 ? --this.lastNtimes : 0;
        this.$cookies.set('ct', count, { expires: '10Y' });
      }
    },
    getSearchCount: function () {
      if (this.$cookies.get('ct') !== null) {
        this.lastNtimes = this.$cookies.get('ct');
      }
      return this.lastNtimes;
    },
    disableSearchField: function () {
      this.disabledSearch = true;
    },
    enableSearchField: function () {
      this.disabledSearch = false;
    },
    createBrowserID: function () {
      const uuid = this.$cookies.get('token');
      this.$data.browserId = uuid;
      if (uuid === null) {
        this.$data.browserId = uuidv1();
        this.$cookies.set('token', this.$data.browserId, { expires: '10Y' });
      }
    },
    makeAnalyticsLabel: function () {
      let label = this.selectedRadio;
      if (this.analyticsUserId !== '') {
        label += `, ${this.analyticsUserId}`;
      }
      return label;
    },
    getEnableConnect: function () {
      // firebaseのデータを取得する
      // TODO レコード数が増えたときには時間がかかる処理なので対処する
      getEnableHospitalList()
        .then((list) => {
          // ログインしているユーザー自身がコネクト導入しているか判定する
          // firestoreのConnectEnableHopitalCollenctionのIDを取得する
          const userConnectCheckList = list.map((list) => list.id).filter((v) => v); // 空の配列除去

          // ログインしているユーザー自身がコネクト導入しているか
          let myConnectStatus = userConnectCheckList.includes(this.loginId);

          // Vuexへ
          this.$store.commit('myConnectStatus', myConnectStatus);

          // つぎに、コネクト導入対象施設を取得する
          this.getConnectMarker(list);
        })
        .catch((e) => {
          // console.log("error firebase");
          this.$sentry.captureException(e);
        });
    },
    getConnectMarker(list) {
      // connect_modeに値があるものだけを抽出
      this.enableConnectList = list.filter((v) => v.connect_mode);
      // 施設カテゴリ毎で処理の判定を行う
      this.enableFacilityList();
    },
    enableFacilityList() {
      // 施設カテゴリ毎でコネクト利用をしている施設を絞りみ、resultEnableConnectListに入れる
      switch (this.selectedRadio) {
        case '病院':
          // 病院の場合はそのままidを使う
          this.resultEnableConnectList = this.enableConnectList;

          // マッピングする施設IDがあるか確認する
          const result = makeMappingEnableConnectList(this.enableConnectList);
          if (result) {
            this.resultEnableConnectList.push(...result);
          }

          // コネクトモード判定
          this.connectModefilter();
          break;

        case '訪看':
          let houkan_List = [];
          // build_typesが訪看のものを取得する
          houkan_List = this.enableConnectList
            .map((v) => v)
            .filter((v) => v.build_types && v.build_types.includes(BUILD_TYPE_MAP.HOUKAN));
          // コネクトモード判定で利用する値を配列に入れる
          houkan_List.map((v) => {
            if (v.id && v.search_facility_id) {
              this.resultEnableConnectList.push({
                id: v.search_facility_id,
                connect_mode: v.connect_mode,
                enableIpAddressList: v.enableIpAddressList,
                connect_enable_receive_date: v.connect_enable_receive_date
              });
            }
          });
          // コネクトモード判定
          this.connectModefilter();
          break;

        case '訪診':
          // build_typesが訪診のものを取得する
          const houshin_List = this.enableConnectList
            .map((v) => v)
            .filter((v) => v.build_types && v.build_types.includes(BUILD_TYPE_MAP.HOUKAN)); // コネクトモードは訪看として扱っている
          // コネクトモード判定で利用する値を配列に入れる
          houshin_List.map((v) => {
            if (v.id && v.search_facility_id) {
              this.resultEnableConnectList.push({
                id: v.search_facility_id,
                connect_mode: v.connect_mode,
                enableIpAddressList: v.enableIpAddressList,
                connect_enable_receive_date: v.connect_enable_receive_date
              });
            }
          });
          // コネクトモード判定
          this.connectModefilter();
          break;

        case '老健':
          let rouken_List = [];
          // build_typesが老健のものを取得する
          rouken_List = this.enableConnectList
            .map((v) => v)
            .filter((v) => v.build_types && v.build_types.includes(BUILD_TYPE_MAP.ROUKEN));
          // コネクトモード判定で利用する値を配列に入れる
          rouken_List.map((v) => {
            if (v.id && v.search_facility_id) {
              this.resultEnableConnectList.push({
                id: v.search_facility_id,
                connect_mode: v.connect_mode,
                enableIpAddressList: v.enableIpAddressList,
                connect_enable_receive_date: v.connect_enable_receive_date
              });
            }
          });
          // コネクトモード判定
          this.connectModefilter();
          break;

        default:
          // 近医、特養、医療院、包括は現状対象外なので空の配列を入れる
          this.resultEnableConnectList = [];
          // コネクトモード判定
          this.connectModefilter();
          break;
      }
    },
    connectModefilter() {
      // コネクトモード判定を行う
      // 初期化
      this.resultConnectModeList = [];
      // UNIXTIMESTAMPを取得
      let time_now = this.$moment().unix();

      // 出しor受けor両方ごとにそれぞれの処理を行う
      for (const data of this.resultEnableConnectList) {
        switch (data.connect_mode) {
          // 出しのみの場合
          case CONNECT_MODE_MAP.SENDER:
            // コネクトIPアドレス許可に1.1.1.1が設定されていない場合に取得
            if (!data.enableIpAddressList.includes(DEFAULT_IP_ADDRESS)) {
              this.resultConnectModeList.push({
                id: data.id,
                connect_mode: data.connect_mode,
                conncect_build_types: data.build_types
              });
            }
            break;
          // 受入のみの場合
          case CONNECT_MODE_MAP.RECEIVER:
            // 受入開始日が現在時点より前の場合に取得
            if (
              data.connect_enable_receive_date &&
              data.connect_enable_receive_date.seconds < time_now
            ) {
              this.resultConnectModeList.push({
                id: data.id,
                connect_mode: data.connect_mode,
                conncect_build_types: data.build_types
              });
            }
            break;
          // 両方の場合
          case CONNECT_MODE_MAP.BOTH:
            // 受入開始日が入っているもの
            if (data.connect_enable_receive_date) {
              this.resultConnectModeList.push({
                id: data.id,
                conncect_build_types: data.build_types,
                connect_mode:
                  // connect_enable_receive_dateの日付が現在より未来の場合は、ふきだしの表示は出し側のみ用にする
                  data.connect_enable_receive_date.seconds > time_now
                    ? CONNECT_MODE_MAP.SENDER
                    : CONNECT_MODE_MAP.BOTH
              });
            }
            break;
          default:
          // 何もしない
        }
      }
    },
    browserSessionCheck: function () {
      // 検索時点のUNIXTIMESTAMPを取得
      let searchNowTimestamp = this.$moment().unix();

      // searchLimitTimeStampに値がない（初回)
      if (!this.searchLimitTimeStamp) {
        let seachLimitTime = this.$moment
          .unix(searchNowTimestamp)
          .add(SEARCH_RELOAD_LIMIT, 'days') // 秒指定： seconds 日指定: days
          .unix();

        this.$store.commit('searchLimitTimeStamp', seachLimitTime);
        return true;
      } else {
        //　二度目の検索以降は、検索したTimeStampとStoreのsearchLimitTimeStampを比較してBooleanを返す
        return searchNowTimestamp < this.searchLimitTimeStamp;
      }
    },
    initFirebase: function () {
      // デフォルトのカテゴリをeventにもカウントされるように事前セット
      const sendGA = (userId) => {
        this.$analytics({
          // send Google Analytics
          eventCategory: 'カテゴリを表示',
          eventAction: '病院',
          eventLabel: userId ? `病院, ${userId}` : '病院'
        });
      };

      // sign-in, sign-outのイベントリスナ
      onAuthStateChanged(auth, (user) => {
        // まず検索不可にして、showNotifyの処理中で有効化/無効化の判断する
        this.disableSearchField();
        if (user && !user.isAnonymous) {
          // User is signed in.
          // ログインtrueflaseをVuexに保存
          this.$store.commit('loginStatus', user.uid ? true : false);
          this.disabledSearch = false;
          this.isLoggedInUser = true;

          // send Google Analytics
          const userId = user.email
            ? `${user.email}__${this.$data.browserId}`
            : this.$data.browserId;
          this.$store.commit('updateAnalyticsUserId', userId);
          // GA4で取得するデータをstoreに格納する
          this.$store.commit('setAnalyticsParams', {
            user_id: userId,
            user_email: user.email,
            browser_id: this.$data.browserId
          });
          // Sentryへ送るデータをセットアップ
          this.$sentry.setUser({
            id: this.$data.browserId,
            email: user.email
          });
          sendGA(userId);

          try {
            // メールアドレスからドメイン除いたのが施設のID
            this.loginId = user.email.endsWith('@3sunny.net') ? 'member' : user.email.split('@')[0];
            // 管理者アカウントの場合
            this.enableAdminMode =
              user.email === 'member@carebook.jp' || user.email.endsWith('@3sunny.net');
            // ログインユーザが管理者IDの場合
            if (this.isLoggedInUser && this.enableAdminMode) {
              // vuexに保存
              this.$store.commit('enableAdminMode', this.enableAdminMode);
            }
            // FIXME: エラー握りつぶしてる
          } catch (error) {}

          // ログインしているユーザには、Connect導入済みユーザ一覧を取得する
          this.getEnableConnect();
        } else {
          // No user is signout.

          // Google Analytics
          this.$store.commit('setAnalyticsParams', {
            user_id: this.$data.browserId,
            browser_id: this.$data.browserId
          });
          sendGA();

          this.isLoggedInUser = false;
        }

        // スマートフォンか確認
        this.platformCheck();

        // 検索回数のダイアログを表示する
        this.showNotify();
      });
    },
    sendSentry: function (errorDetail, errorData) {
      // sentryへ送信する
      this.$sentry.captureException(
        new Error(`${errorDetail}. ${JSON.stringify(errorData, null, 2)}`)
      );
    }
  },
  computed: {
    ...mapGetters({
      isLogin: 'loginStatus',
      currentBounds: 'bounds', // 現在の位置情報
      selectedRadio: 'radios',
      boundsStation: 'features',
      analyticsUserId: 'analyticsUserId',
      myConnectStatus: 'connectStatus',
      searchLimitTimeStamp: 'searchLimitTime',
      analyticsParams: 'analyticsParams'
    }),
    loadingErrorData: function () {
      // sentryに送信するエラー一覧
      return {
        workerProcess: this.workerProcessCheck, // trueの場合は正常にdatファイルが展開されている
        firstView: this.firstView, // trueの場合は loadGeoData() の処理は通っている
        filteredResultCount: !!this.filteredResultCount // trueの場合は施設データの絞り込みまで完了しているため、施設データがあり、意図した通りの結果となっているはず
      };
    },
    isSearchHitlimit: function () {
      // 管理者の場合は表示する件数を変える
      return this.enableAdminMode
        ? (this.searchHitlimit = ADMIN_SEARCH_HIT_LIMIT)
        : (this.searchHitlimit = SEARCH_HIT_LIMIT);
    }
  },
  mounted: async function () {
    const loader = new Loader({
      apiKey: mapSettings.apiKey,
      version: 'quarterly',
      libraries: ['places']
    });
    this.google = await loader.load();
    this.getSearchCount();
    this.loadGeoData();
    this.autoCompleteSearch();
    this.createBrowserID();
  },
  created() {
    window.localStorage.removeItem('chousei-kun'); // サーバサイドで実行するとエラーになる為ここで実行

    /** アプリバージョンを送信する */
    const __VERSION__ = JSON.stringify(require('../../package').version);
    let applyVersion_tmp = __VERSION__;
    this.applyVersion = applyVersion_tmp.replace(/"/g, '');
    // GA4で取得するデータをstoreに格納する
    this.$store.commit('setAnalyticsParams', {
      app_version: __VERSION__
    });

    this.initFirebase();
  }
};


import { mapGetters } from 'vuex';
import CloseIcon from '~/static/img/icons/info-close.svg?inline';

export default {
  name: 'GeolocationDialog',
  data() {
    return {
      showgeoDialog: true,
      setLoading: false,
      settingMessage: false,
      errorMessage: false
    };
  },
  components: {
    CloseIcon
  },
  computed: {
    ...mapGetters({
      geodataFeatures: 'bounds', // 表示している場所の中心の位置
      analyticsUserId: 'analyticsUserId'
    }),
    isSettingMessage() {
      return this.settingMessage ? '設定が完了しました' : 'この場所を初期表示にする';
    }
  },
  methods: {
    getLocation: async function () {
      try {
        this.setLoading = true;
        setTimeout(() => {
          localStorage.setItem(
            'chousei-kun-setLocation',
            JSON.stringify(this.geodataFeatures.center)
          );
          this.setLoading = false;
          this.settingMessage = true;

          // GA送信
          this.$analytics({
            eventCategory: '初期表示の場所を設定',
            eventAction: '設定した初期表示の場所' + JSON.stringify(this.geodataFeatures.center),
            eventLabel: this.analyticsUserId
          });
        }, 1000);
      } catch (e) {
        this.$sentry.captureException(e);
        this.setLoading = false;
        // エラーメッセージを出す
        this.errorMessage = true;
      }
    },
    closeShowgeoDialog: function () {
      //ダイアログが閉じたとき(falseになった時)は$emitを使って親コンポーネントに伝える
      this.$emit('g_close');
      this.showgeoDialog = false;
    }
  },
  watch: {
    showgeoDialog() {
      if (!this.showgeoDialog) {
        //ダイアログが閉じられる時の処理
        this.closeShowgeoDialog();
      }
    },
    settingMessage() {
      // 設定が完了したら、少し時間を置いてダイアログを閉じる
      setTimeout(() => {
        this.closeShowgeoDialog();
      }, 1300);
    }
  },
  mounted() {
    setTimeout(() => {
      this.settingMessage = false;
    }, 200);
  }
};

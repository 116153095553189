import * as Sentry from '@sentry/vue';
import Vue from 'vue';
const __VERSION__ = JSON.stringify(require('../../package').version);

const isDevelop = () => {
  return process.env.NODE_ENV === 'development';
};

Sentry.init({
  Vue,
  dsn: process.env.sentryDsn,
  release: __VERSION__,
  debug: isDevelop(),
  tracesSampleRate: 1.0,
  integrations: [Sentry.browserTracingIntegration()],
  tracingOptions: {
    trackComponents: true
  },
  autoSessionTracking: true
});

Vue.prototype.$sentry = Sentry;

import { ASSET_TYPE, AssetType } from '~/components/settings/variables';

export const useGeoData = () => {
  const DEFAULT_ASSET_TYPE = ASSET_TYPE.hospital;
  const TOTAL_DATA_TYPE = Object.keys(ASSET_TYPE).length;
  const PROGRESS_PER = Math.round(100 / TOTAL_DATA_TYPE);
  const PROGRESS_COMPLETE = 100;

  type GeoDataType = {
    regex: RegExp;
    type: AssetType;
  };

  const geoDataTypes: Array<GeoDataType> = [
    { regex: /HoukanGeoData/, type: ASSET_TYPE.houkan },
    { regex: /HospitalGeoData/, type: ASSET_TYPE.hospital },
    { regex: /ClinicGeoData/, type: ASSET_TYPE.clinic },
    { regex: /AreaGeoData/, type: ASSET_TYPE.area },
    { regex: /RoukenGeoData/, type: ASSET_TYPE.rouken },
    { regex: /KakaritukeGeoData/, type: ASSET_TYPE.kakarituke },
    { regex: /TokuyouGeoData/, type: ASSET_TYPE.tokuyou },
    { regex: /KaigoIryouInGeoData/, type: ASSET_TYPE.kaigoiryouin }
  ];

  const GEO_DATA_TARGET = [
    { Topic: 'updateHoukanGeoData', Url: '/data/houkan.dat', Cache: true },
    { Topic: 'updateHospitalGeoData', Url: '/data/hospital.dat', Cache: true },
    { Topic: 'updateClinicGeoData', Url: '/data/clinic.dat', Cache: true },
    { Topic: 'updateRoukenGeoData', Url: '/data/rouken.dat', Cache: true },
    { Topic: 'updateKakaritukeGeoData', Url: '/data/kakarituke.dat', Cache: true },
    { Topic: 'updateTokuyouGeoData', Url: '/data/tokuyou.dat', Cache: true },
    {
      Topic: 'updateKaigoIryouInGeoData',
      Url: '/data/kaigoiryouin.dat',
      Cache: true
    },
    { Topic: 'updateAreaGeoData', Url: '/data/area.dat', Cache: true }
  ];

  const getGeoDataType = (topic: string): AssetType => {
    const FIRST = 0;
    return geoDataTypes.filter((geoDataType) => {
      return geoDataType.regex.test(topic);
    })[FIRST].type;
  };

  return {
    getGeoDataType,
    GEO_DATA_TARGET,
    DEFAULT_ASSET_TYPE,
    PROGRESS_PER,
    PROGRESS_COMPLETE
  };
};


export default {
  name: 'SorryDialog',
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    open: function () {
      this.dialog = true;
    },
    close: function () {
      this.dialog = false;
    },
    updateSW: function () {
      try {
        navigator.serviceWorker.getRegistration().then((registration) => {
          if (typeof registration !== 'undefined') {
            registration.update();
            window.location.reload(true);
          } else {
            window.location.reload(true);
          }
        });
      } catch (e) {
        window.location.reload(true);
      }
    }
  }
};

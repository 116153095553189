import { render, staticRenderFns } from "./SearchToolbar.vue?vue&type=template&id=38c5ae69&scoped=true"
import script from "./SearchToolbar.vue?vue&type=script&lang=js"
export * from "./SearchToolbar.vue?vue&type=script&lang=js"
import style0 from "./SearchToolbar.vue?vue&type=style&index=0&id=38c5ae69&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38c5ae69",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DetailsButton: require('/home/runner/work/cb-search-nuxt/cb-search-nuxt/components/Atoms/DetailsButton.vue').default})

// keyがdatで定義しているカラム名。valueが表示する時のタグ名
export const tagTypeHospital = {
  一般: '一般',
  感染: '感染',
  精神: '精神',
  結核: '結核',
  障害: '障がい者',
  介護療養: '介護療養',
  医療療養: '医療療養',
  緩和ケア: '緩和ケア',
  認知症: '認知症',
  回復期リハ: '回復期リハ',
  地域包括ケア: '地域包括ケア',
  透析: '透析'
};

export const tagTypeHoukan = {
  PT: 'PT/OT在籍',
  OT: 'PT/OT在籍',
  ST: 'ST在籍'
};

export const tagTypeKakarituke = {
  小か診: 'かかりつけ医(小児)',
  支援診: '在宅も対応可能',
  地包加: 'かかりつけ医(成人)'
};

export const tagTypeRouken = {
  unit_single: 'ユニット型個室',
  unit_multi: 'ユニット型個室的多床室',
  unit_oldtype: '従来型個室',
  multi: '多床室',
  zaitaku: '在宅強化型'
};

export const tagTypeShienshin = {
  診入院: '病床あり'
};

export const tagTypeKaigoIryouIn = {
  併設_病院: '病院併設',
  併設_診療所: '診療所併設',
  併設_訪看: '訪問看護併設'
};

import { render, staticRenderFns } from "./ClinicStationCard.vue?vue&type=template&id=56a74fda&scoped=true"
import script from "./ClinicStationCard.vue?vue&type=script&lang=js"
export * from "./ClinicStationCard.vue?vue&type=script&lang=js"
import style0 from "./ClinicStationCard.vue?vue&type=style&index=0&id=56a74fda&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56a74fda",
  null
  
)

export default component.exports
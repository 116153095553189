/**
 * firestoreのupdateInfoCollectionから更新通知を取得する機能
 */
import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore/lite';
import moment from 'moment';
import { infoMessageType } from 'types/GetFirebaseDataTypes';
import { db } from '~/components/settings/firebase';

const TARGET_COLLECTION_NAME = 'updateInfoCollection';

export default async function () {
  try {
    const q = query(collection(db, TARGET_COLLECTION_NAME), orderBy('update', 'desc'), limit(10));
    const querySnapshot = await getDocs(q);
    const ret: infoMessageType[] = [];
    querySnapshot.forEach((doc) => {
      ret.push({
        message: doc.data().message,
        update: moment(doc.data().update.seconds * 1000).format('YYYY/MM/DD'),
        timestamp: doc.data().update.seconds * 1000
      });
    });
    return ret;
  } catch (error) {
    // 何もしない
  }
}

/**
 * firestoreからコネクトが有効なクライアント情報を取得する機能
 */
import { collection, getDocs, query } from 'firebase/firestore/lite';
import { EnableHospitalType } from 'types/GetFirebaseDataTypes';
import { db } from '~/components/settings/firebase';

const TARGET_COLLECTION_NAME = 'ConnectEnabledHospitalCollection';

export default async function () {
  try {
    const q = query(collection(db, TARGET_COLLECTION_NAME));
    const querySnapshot = await getDocs(q);
    const ret: EnableHospitalType[] = [];
    querySnapshot.forEach((doc) => {
      ret.push(doc.data() as EnableHospitalType);
    });
    return ret;
  } catch (error) {
    // 何もしない
  }
}

import { render, staticRenderFns } from "./HospitalDetailDialog.vue?vue&type=template&id=255d418f&scoped=true"
import script from "./HospitalDetailDialog.vue?vue&type=script&lang=js"
export * from "./HospitalDetailDialog.vue?vue&type=script&lang=js"
import style0 from "./HospitalDetailDialog.vue?vue&type=style&index=0&id=255d418f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "255d418f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SorryDialog: require('/home/runner/work/cb-search-nuxt/cb-search-nuxt/components/Atoms/SorryDialog.vue').default})

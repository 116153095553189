
import { mapGetters } from 'vuex';

export default {
  name: 'DataNotFound',
  computed: {
    getCounts() {
      let msg = '';
      if (this.count > 1000) {
        msg = '1,000件以上';
      } else {
        msg = this.count + '件';
      }
      return msg;
    },
    ...mapGetters({
      count: 'allFeatures'
    })
  }
};


import { mapGetters } from 'vuex';
import { ASSET_TYPE } from '../components/settings/variables';
import SearchToolbar from '~/components/molecules/SearchToolbar.vue';
import GoogleMap from '~/components/molecules/GoogleMap.vue';
import HoukanStationCard from '~/components/molecules/HoukanStationCard.vue';
import HospitalStationCard from '~/components/molecules/HospitalStationCard.vue';
import KakaritukeStationCard from '~/components/molecules/KakaritukeStationCard.vue';
import ClinicStationCard from '~/components/molecules/ClinicStationCard.vue';
import RoukenStationCard from '~/components/molecules/RoukenStationCard.vue';
import TokuyouStationCard from '~/components/molecules/TokuyouStationCard.vue';
import KaigoIryouInStationCard from '~/components/molecules/KaigoIryouInStationCard.vue';
import AreaStationCard from '~/components/molecules/AreaStationCard.vue';

export default {
  name: 'mapLayout',
  data() {
    return {
      changeCard: this.$options.components.HospitalStationCard
    };
  },
  components: {
    SearchToolbar,
    GoogleMap,
    HospitalStationCard,
    KakaritukeStationCard,
    HoukanStationCard,
    ClinicStationCard,
    RoukenStationCard,
    TokuyouStationCard,
    KaigoIryouInStationCard,
    AreaStationCard
  },
  watch: {
    selectedRadio: {
      handler: function (val, oldVal) {
        switch (this.selectedRadio) {
          case ASSET_TYPE.houkan:
            return (this.changeCard = this.$options.components.HoukanStationCard);
            break;
          case ASSET_TYPE.hospital:
            return (this.changeCard = this.$options.components.HospitalStationCard);
            break;
          case ASSET_TYPE.kakarituke:
            return (this.changeCard = this.$options.components.KakaritukeStationCard);
            break;
          case ASSET_TYPE.clinic:
            return (this.changeCard = this.$options.components.ClinicStationCard);
            break;
          case ASSET_TYPE.rouken:
            return (this.changeCard = this.$options.components.RoukenStationCard);
            break;
          case ASSET_TYPE.tokuyou:
            return (this.changeCard = this.$options.components.TokuyouStationCard);
            break;
          case ASSET_TYPE.kaigoiryouin:
            return (this.changeCard = this.$options.components.KaigoIryouInStationCard);
            break;
          case ASSET_TYPE.area:
            return (this.changeCard = this.$options.components.AreaStationCard);
            break;
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      selectedRadio: 'radios'
    })
  }
};


import { mapGetters } from 'vuex';
import getHospitalDetail from '../utils/get-hospital-detail';
import ConnectEmptyBedInfoDialog from './HospitalDetailDialog';
import ConnectprDialog from './ConnectPrDialog';
import ConnectdetailDialog from './ConnectDetailDialog';
import { BED_TYPES, LABEL_SHOW_DISPLAY_WORD } from '~/components/settings/variables';
import { tagTypeHospital } from '../utils/tagType';
import { checkMappingFacilityId } from '../utils/facilityMapping';
import DataNotfound from '~/components/Atoms/DataNotFound.vue';

const mapSettings = require('../settings/map'); // google map
// [重要] firestoreへのリクエストの並列上限を設定しておく
// なにかの不具合で並列数が跳ね上がった時のための安全装置
const MAX_REQUEST_SAFETY_LIMIT = mapSettings.searchHitLimit;

// keyがgeoData上の名称。valueが画面上の表示名という対応
const tagType = tagTypeHospital;

// FIXME 空床情報機能の有効/無効 有効 = true
const ENABLE_BED_STATUS_FUNCTION = true;
const BED_STATUS_FULL = 'full';
const BED_STATUS_EMPTY = 'empty';

// Google analytics送信用
const TARGET_ASSET_TYPE = '病院';
const GA_DELAY = 1000 * 3;

// 例外対応 19床以下の病院(=有床診療所)のラベル
const YUSHOU_HOSPITAL_LABEL = '有床診療所';

let timerId; // GA送信判定に使うタイマーを格納
let gaLastSentId; // 最後にGAを送信したクライアントID

export default {
  data() {
    return {
      loading: true,
      bedDetail: [],
      connectEmptyBedInfoDialog: false,
      connectPrDialog: false,
      connectDetailDialog: false,
      visibleItem: { properties: '', bed: '' },
      promotionItem: { properties: '', analyticsUserId: '' }
    };
  },
  components: {
    ConnectEmptyBedInfoDialog,
    ConnectprDialog,
    ConnectdetailDialog,
    DataNotfound
  },
  methods: {
    onMouseOver(item) {
      // map側でselectedにされたとき、こちらで検知できないので、まずcssを一律リセットする
      const selectedElements = document.getElementsByClassName('station-list__selected');
      Array.prototype.forEach.call(selectedElements, (ele) => {
        ele.setAttribute('class', 'station-list__normal');
      });
      // 描画される前にマウスオーバーするとエラーになる
      document.getElementById(item.properties.id).setAttribute('class', 'station-list__selected');
      // mouseoverが3秒続いたらGAにデータ送信
      timerId = setTimeout(() => {
        if (item.properties.id === gaLastSentId) {
          // 連続して同じ企業名を送信しないようにする
          return;
        }
        this.$analytics({
          // send Google Analytics
          eventCategory: '個社ウォッチ',
          eventAction: `${item.properties.company_name} (tel: ${item.properties.tel})`,
          eventLabel: this.makeAnalyticsLabel()
        });
        gaLastSentId = item.properties.id;
      }, GA_DELAY);

      // this.$emit("bound-map-marker", item.properties.id);
      this.$store.commit('mapMarkerid', item.properties.id);
    },
    onMouseLeave(item) {
      try {
        document.getElementById(item.properties.id).setAttribute('class', 'station-list__normal');
        clearTimeout(timerId); // ga timer
      } catch (e) {
        // 頻繁に切り替えると発生しやすい。無視してよい。sentryも送らない
      }
    },
    clickHomepage(item) {
      // HPリンク時にga送信
      this.$analytics({
        // send Google Analytics
        eventCategory: '公式サイトクリック',
        eventAction: `${item.properties.company_name} (tel: ${item.properties.tel})`,
        eventLabel: this.makeAnalyticsLabel()
      });
    },
    clickRoute(item) {
      // ルートを探す押下時にga送信
      this.$analytics({
        // send Google Analytics
        eventCategory: 'ルートを探すクリック',
        eventAction: `${item.properties.company_name} (tel: ${item.properties.tel})`,
        eventLabel: this.makeAnalyticsLabel()
      });
    },
    visibleHomepage(item) {
      let visible = false;
      try {
        visible =
          item.properties.url !== '' &&
          item.properties.url !== '不明' &&
          typeof item.properties.url !== 'undefined';
      } catch (e) {
        this.$sentry.captureException(e);
      }
      return visible;
    },
    makeGoogleRouteUrl(item) {
      // google ルート検索のURL生成
      const lat = item.geometry.coordinates[1];
      const lng = item.geometry.coordinates[0];
      return `https://www.google.co.jp/maps/dir//${lat},${lng}/@${lat},${lng},17z`;
    },
    getFetchBedData(item) {
      const resultFaclityId = checkMappingFacilityId(item.properties.id); // マッピングする施設IDがあるが確認する
      return this.bedDetail.filter((v) => String(v.id) === String(resultFaclityId));
    },
    hasBedData(item) {
      return !!this.getFetchBedData(item).length;
    },
    visibleLabels(item) {
      const labels = [];
      try {
        for (let key in tagType) {
          // ラベルが重複している場合は以降の処理をスキップして次のループへ
          if (labels.includes(tagType[key])) continue;

          if (LABEL_SHOW_DISPLAY_WORD.includes(item.properties[key])) {
            labels.push(tagType[key]);
          }
        }
        // 19床以下の施設がある場合は有床診療所のラベルを付与する
        if (this.makeYuusyouLabel(item)) {
          labels.push(YUSHOU_HOSPITAL_LABEL);
        }
      } catch (e) {
        this.$sentry.captureException(e);
      }
      return labels;
    },
    getUpdate(item) {
      let update = '';
      try {
        if (typeof item.properties.update !== 'undefined') {
          update = this.$moment(item.properties.update, 'YYYY-MM-DDTHH:mm:ssZ').format(
            '更新日: YYYY/MM/DD'
          );
        }
        // fetchデータに該当があるか
        let hitData = this.getFetchBedData(item);
        // 該当あり
        if (hitData.length) {
          hitData = hitData.pop();
          update = this.$moment(hitData.updateAt.toDate(), 'YYYY-MM-DDTHH:mm:ssZ').format(
            '更新日: YYYY/MM/DD'
          );
        }
      } catch (e) {
        // console.log("getUpdate error");
        this.$sentry.captureException(e);
        update = '';
      }
      return update;
    },
    openConnectEmptyBedInfoDialog(item) {
      if (this.isLogin) {
        const bedDataArray = this.getFetchBedData(item);
        const bedData = bedDataArray.length ? bedDataArray.pop() : {};

        this.visibleItem = {
          properties: item.properties,
          bed: bedData,
          analyticsUserId: this.analyticsUserId,
          radio_type: TARGET_ASSET_TYPE
        };
        this.connectEmptyBedInfoDialog = true; // ダイアログ表示
      }
      this.$analytics({
        // send Google Analytics
        eventCategory: '詳細を表示',
        eventAction: `(詳細表示)${item.properties.company_name}`,
        eventLabel: this.makeAnalyticsLabel()
      });
    },
    openConnectprDialog(item) {
      this.promotionItem = {
        properties: item.properties,
        analyticsUserId: this.makeAnalyticsLabel()
      };
      this.connectPrDialog = true;
      this.$analytics({
        // send Google Analytics
        eventCategory: 'ケアブックLP詳細を表示',
        eventAction: `(ケアブックLP詳細を表示)${item.properties.company_name}`,
        eventLabel: this.makeAnalyticsLabel()
      });
    },
    openConnectdetailDialog(item) {
      this.promotionItem = {
        properties: item.properties,
        analyticsUserId: this.makeAnalyticsLabel()
      };
      this.connectDetailDialog = true;
      this.$analytics({
        // send Google Analytics
        eventCategory: 'コネクト詳細を表示',
        eventAction: `(コネクト詳細表示)${item.properties.company_name}`,
        eventLabel: this.makeAnalyticsLabel()
      });
    },
    closeConnectEmptyBedInfoDialog() {
      this.connectEmptyBedInfoDialog = false;
    },
    closeConnectprDialog() {
      this.connectPrDialog = false;
    },
    closeConnectdetailDialog() {
      this.connectDetailDialog = false;
    },
    visibleLabelsWithBed(item) {
      const labels = [];
      try {
        const sum = (accumulator, currentValue) => Number(accumulator) + Number(currentValue);
        // fetchデータに該当があるか
        let hitData = this.getFetchBedData(item);
        // 該当あり
        if (hitData.length) {
          hitData = hitData.pop();
          for (let key in tagType) {
            if (LABEL_SHOW_DISPLAY_WORD.includes(item.properties[key])) {
              // 病床種別の空きがあるか
              let statusLabel = '';
              const bedKey = BED_TYPES[key];
              const bedCount = Object.assign({}, hitData[bedKey]);
              if (typeof hitData[bedKey] === 'undefined') {
                statusLabel = '';
              } else if (sum(bedCount.man, bedCount.woman)) {
                statusLabel = BED_STATUS_EMPTY;
              } else {
                statusLabel = BED_STATUS_FULL;
              }
              labels.push({
                name: tagType[key],
                status: statusLabel
              });
            }
          }
          // 例外対応：19床以下の施設がある場合は有床診療所のラベルを付与する
          if (this.makeYuusyouLabel(item)) {
            labels.push({ name: YUSHOU_HOSPITAL_LABEL, status: '' });
          }
        }
        // なしの時はそのまま通常ラベル表示
        else {
          for (let key in tagType) {
            if (LABEL_SHOW_DISPLAY_WORD.includes(item.properties[key])) {
              labels.push({
                name: tagType[key],
                status: ''
              });
            }
          }
          // 例外対応：19床以下の施設がある場合は有床診療所のラベルを付与する
          if (this.makeYuusyouLabel(item)) {
            labels.push({ name: YUSHOU_HOSPITAL_LABEL, status: '' });
          }
        }
      } catch (e) {
        this.$sentry.captureException(e);
      }
      return labels;
    },
    getbedDetail() {
      if (this.enableBedStatusFunction) {
        this.loading = true;
        const tasks = [];
        let safety_counter = 0;
        for (const feature of this.geodataFeatures) {
          if (safety_counter < MAX_REQUEST_SAFETY_LIMIT) {
            // マッピングする施設IDがあるが確認する
            const resultFaclityId = checkMappingFacilityId(feature.properties.id);
            tasks.push(getHospitalDetail(resultFaclityId));
            safety_counter++;
          }
        }
        // noinspection JSCheckFunctionSignatures
        Promise.all(tasks).then((ret) => {
          this.bedDetail = ret.filter((v) => v.clientId); // 空の配列削除
          this.$store.commit('emptyBedinfoList', this.bedDetail);
          this.$nextTick(() => {
            this.loading = false;
          });
        });
      } else {
        // 空床情報機能が無効なとき
        this.loading = false;
      }
    },
    makeAnalyticsLabel() {
      let label = TARGET_ASSET_TYPE;
      if (typeof this.analyticsUserId !== 'undefined' && this.analyticsUserId !== '') {
        label += `, ${this.analyticsUserId}`;
      }
      return label;
    },
    makeYuusyouLabel(item) {
      // 例外対応：19床以下の施設がある場合は有床診療所のラベルを付与する
      // Todo:数が増えてきたら決め打ちでの判定をやめて総病床数で判断するようにする
      // id8820:松下内科リハビリクリニック
      const YUSHOU_HOSPITAL = ['8820', 'cw804'];
      return YUSHOU_HOSPITAL.includes(item.properties.id);
    }
  },
  computed: {
    ...mapGetters({
      geodataFeatures: 'features',
      isLogin: 'loginStatus',
      analyticsUserId: 'analyticsUserId',
      myConnectStatus: 'connectStatus'
    }),
    bedStatusFull() {
      return BED_STATUS_FULL;
    },
    bedStatusEmpty() {
      return BED_STATUS_EMPTY;
    },
    enableBedStatusFunction() {
      return ENABLE_BED_STATUS_FUNCTION;
    }
  },
  watch: {
    geodataFeatures() {
      // ログイン時は空床情報を取得する
      if (this.isLogin) {
        this.getbedDetail();
      } else {
        this.loading = false;
      }
    }
  }
};

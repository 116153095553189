
const DEFAULT_COLOR_CHECKED = '#f85863';
const DEFAULT_COLOR_UNCHECKED = '#d2d2d2';
const DEFAULT_SWITCH_COLOR = '#fff';
const px = (v) => v + 'px';
export default {
  name: 'BaseToggleButton',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    sync: {
      type: Boolean,
      default: false
    },
    speed: {
      type: Number,
      default: 300
    },
    height: {
      type: Number,
      default: 22
    },
    width: {
      type: Number,
      default: 46
    },
    margin: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      toggled: !!this.value
    };
  },
  computed: {
    className() {
      const { toggled, disabled } = this;
      return ['details-switch', { toggled, disabled }];
    },
    coreStyle() {
      return {
        width: px(this.width),
        height: px(this.height),
        backgroundColor: this.colorCurrent,
        borderRadius: px(Math.round(this.height / 2))
      };
    },
    distance() {
      return px(this.width - this.height + this.margin);
    },
    buttonStyle() {
      const transition = `transform ${this.speed}ms`;
      const margin = px(this.margin);
      const translate3d = (x, y, z = '0px') => {
        return `translate3d(${x}, ${y}, ${z})`;
      };
      const transform = this.toggled
        ? translate3d(this.distance, margin)
        : translate3d(margin, margin);
      const background = DEFAULT_SWITCH_COLOR;
      return {
        transition,
        transform,
        background
      };
    },
    colorChecked() {
      return DEFAULT_COLOR_CHECKED;
    },
    colorUnchecked() {
      return DEFAULT_COLOR_UNCHECKED;
    },
    switchColorChecked() {
      return DEFAULT_SWITCH_COLOR;
    },
    switchColorUnchecked() {
      return DEFAULT_SWITCH_COLOR;
    },
    colorCurrent() {
      return this.toggled ? this.colorChecked : this.colorUnchecked;
    },
    switchColorCurrent() {
      return this.toggled ? this.switchColorChecked : this.switchColorUnchecked;
    }
  },
  watch: {
    value(value) {
      if (this.sync) {
        this.toggled = !!value;
      }
    }
  },
  methods: {
    toggle() {
      this.toggled = !this.toggled;
      this.$emit('input', this.toggled);
      if (this.toggled === true) {
        //GA送信するため必要
        this.$emit('change', this.name);
      } else {
        this.$emit('change', null);
      }
    }
  }
};


import { mapGetters } from 'vuex';
import AreadetailDialog from './AreaDetailDialog';
import DataNotfound from '~/components/Atoms/DataNotFound.vue';
import ConnectprDialog from './ConnectPrDialog';
import ConnectdetailDialog from './ConnectDetailDialog';

const TARGET_ASSET_TYPE = '包括';
const UNKNOWN_MESSAGE = '不明';
const GA_DELAY = 1000 * 3; // wait time (msec)
let timerId; // GA送信判定につかうタイマーを格納
let gaLastSentId; // 最後にGAを送信したクライアントID

export default {
  data() {
    return {
      dialog: false,
      isActive: false,
      loading: false,
      detailInfo: {},
      connectPrDialog: false,
      connectDetailDialog: false,
      promotionItem: { properties: '', analyticsUserId: '' }
    };
  },
  components: {
    AreadetailDialog,
    DataNotfound,
    ConnectprDialog,
    ConnectdetailDialog
  },
  computed: {
    ...mapGetters({
      geodataFeatures: 'features',
      isLogin: 'loginStatus',
      analyticsUserId: 'analyticsUserId',
      myConnectStatus: 'connectStatus'
    }),
    getUnknownMessage() {
      return UNKNOWN_MESSAGE;
    }
  },
  methods: {
    visibleInfoTable(item) {
      return !!item.properties['担当地域'];
    },
    makeAnalyticsLabel() {
      let label = TARGET_ASSET_TYPE;
      if (typeof this.analyticsUserId !== 'undefined' && this.analyticsUserId !== '') {
        label += `, ${this.analyticsUserId}`;
      }
      return label;
    },
    visibleHomepage(item) {
      let visible = false;
      try {
        visible =
          item.properties.url !== '' &&
          item.properties.url !== '不明' &&
          typeof item.properties.url !== 'undefined';
      } catch (e) {
        Raven.captureException(e);
      }
      return visible;
    },
    getUpdate(item) {
      let update = '';
      try {
        if (typeof item.properties.update !== 'undefined') {
          update = this.$moment(item.properties.update, 'YYYY/MM/DD HH:mm').format(
            '更新日: YYYY/MM/DD'
          );
        }
      } catch (e) {
        this.$sentry.captureException(e);
        update = '';
      }
      return update;
    },
    openAreaDetailDialog: function (geoData) {
      if (this.isLogin === true) {
        // User is signed in.
        this.$nextTick().then(() => {
          this.$analytics({
            // send Google Analytics
            eventCategory: 'もっとみるボタン押下',
            eventAction: `${this.detailInfo.company_name}(tel: ${this.detailInfo.tel})`,
            eventLabel: this.makeAnalyticsLabel()
          });
        });
        const emptyString = '    -    ';
        this.detailInfo.emptyInfo = emptyString;
        this.detailInfo.company_name = geoData.properties.company_name || '取得に失敗しました';
        this.detailInfo.tel = geoData.properties.tel || emptyString;
        this.detailInfo.fax = geoData.properties.fax || emptyString;
        this.detailInfo.address = geoData.properties['住所'] || emptyString;
        this.detailInfo.access =
          geoData.properties['アクセス'].replace(/\n/g, ' / ') || emptyString;

        // 相談曜日 (平日やってなくて、土日のみって施設は無いので考慮不要)
        if (geoData.properties['相談曜日[平日]'] !== '') {
          this.detailInfo.day = geoData.properties['相談曜日[平日]'] ? '平日' : '';
          this.detailInfo.day += geoData.properties['相談曜日[土曜日]'] ? '・土' : '';
          this.detailInfo.day += geoData.properties['相談曜日[日曜日]'] ? '・日' : '';
          this.detailInfo.day += geoData.properties['相談曜日[祝日]'] ? '・祝' : '';
          this.detailInfo.day += geoData.properties['相談曜日[年末年始]'] ? '・年末年始' : '';
        } else {
          this.detailInfo.day = emptyString;
        }

        // 平日の相談時間
        if (
          geoData.properties['平日相談時間-開始'] === '' ||
          geoData.properties['平日相談時間-終了'] === ''
        ) {
          this.detailInfo.time = emptyString;
        } else {
          this.detailInfo.time = `${geoData.properties['平日相談時間-開始']} - ${geoData.properties['平日相談時間-終了']}`;
        }

        // 緯度経度
        this.detailInfo.latlng = {
          lat: geoData.geometry.coordinates[1],
          lng: geoData.geometry.coordinates[0]
        };

        this.dialog = true; //ダイアログ表示
      } else {
        // this.openLoginRequestDialog();
      }
    },
    closeAreadetailDialog() {
      this.dialog = false;
    },
    onMouseOver(item) {
      // map側でselectedにされたとき、こちらで検知できないので、まずcssを一律リセットする
      const selectedElements = document.getElementsByClassName('station-list__selected');
      if (selectedElements) {
        Array.prototype.forEach.call(selectedElements, (ele) => {
          ele.setAttribute('class', 'station-list__normal');
        });
      }

      document.getElementById(item.properties.id).setAttribute('class', 'station-list__selected');
      // mouseoverが3秒続いたらGAにデータ送信
      timerId = setTimeout(() => {
        if (item.properties.id === gaLastSentId) {
          // 連続して同じ企業名を送信しないようにする
          return;
        }
        this.$analytics({
          // send Google Analytics
          eventCategory: '個社ウォッチ',
          eventAction: `${item.properties.company_name} (tel: ${item.properties.tel})`,
          eventLabel: this.makeAnalyticsLabel()
        });
        gaLastSentId = item.properties.id;
      }, GA_DELAY);

      this.$store.commit('mapMarkerid', item.properties.id);
    },
    onMouseLeave(item) {
      try {
        document.getElementById(item.properties.id).setAttribute('class', 'station-list__normal');
        clearTimeout(timerId); // ga timer
      } catch (e) {
        // 頻繁に切り替えると発生しやすい。無視してよい。sentryも送らない
      }
    },
    clickHomepage(item) {
      // HPリンク時にga送信
      this.$analytics({
        // send Google Analytics
        eventCategory: '公式サイトクリック',
        eventAction: `${item.properties.company_name} (tel: ${item.properties.tel})`,
        eventLabel: this.makeAnalyticsLabel()
      });
    },
    clickRoute(item) {
      // ルートを探す押下時にga送信
      this.$analytics({
        // send Google Analytics
        eventCategory: 'ルートを探すクリック',
        eventAction: `${item.properties.company_name} (tel: ${item.properties.tel})`,
        eventLabel: this.makeAnalyticsLabel()
      });
    },
    makeGoogleRouteUrl(item) {
      // google ルート検索のURL生成
      const lat = item.geometry.coordinates[1];
      const lng = item.geometry.coordinates[0];
      return `https://www.google.co.jp/maps/dir//${lat},${lng}/@${lat},${lng},17z`;
    },
    openConnectprDialog(item) {
      this.promotionItem = {
        properties: item.properties,
        analyticsUserId: this.makeAnalyticsLabel()
      };
      this.connectPrDialog = true;
      this.$analytics({
        // send Google Analytics
        eventCategory: 'ケアブックLP詳細を表示',
        eventAction: `(ケアブックLP詳細を表示)${item.properties.company_name}`,
        eventLabel: this.makeAnalyticsLabel()
      });
    },
    openConnectdetailDialog(item) {
      this.promotionItem = {
        properties: item.properties,
        analyticsUserId: this.makeAnalyticsLabel()
      };
      this.connectDetailDialog = true;
      this.$analytics({
        // send Google Analytics
        eventCategory: 'コネクト詳細を表示',
        eventAction: `(コネクト詳細表示)${item.properties.company_name}`,
        eventLabel: this.makeAnalyticsLabel()
      });
    },
    closeConnectprDialog() {
      this.connectPrDialog = false;
    },
    closeConnectdetailDialog() {
      this.connectDetailDialog = false;
    }
  }
};

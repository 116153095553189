// vueファイル名,エラーコードで記述
module.exports = {
  // SearchToolbar.vue
  ST_ERROR: {
    _00: {
      code: 'ST-00',
      detail: 'ST-00 error:initial.error'
    },
    _10: {
      code: 'ST-10',
      detail: 'ST-10 error:filterData() boundsStation.features.filter.error'
    },
    _20: {
      code: 'ST-20',
      detail: 'ST-20 error:loadGeoData() GeoData = msg.data.Json.error'
    },
    _30: {
      code: 'ST-30',
      detail: 'ST-30 error:worker.addEventListener error'
    },
    _40: {
      code: 'ST-40',
      detail: 'ST-40 error:viewData not Array'
    },
    _50: {
      code: 'ST-50',
      detail: 'ST-50 error:Array.isArray and Data.features.length error'
    },
    _60: {
      code: 'ST-60',
      detail: 'ST-60 error:unbz2Error'
    },
    _70: {
      code: 'ST-70',
      detail: 'ST-70 error:noGetData error'
    },
    _80: {
      code: 'ST-80',
      detail: 'ST-80 error:workerjsError'
    },
    _90: {
      code: 'ST-90',
      detail: 'ST-90 error:ロゴクリックservice workerの更新処理でエラー'
    }
  },
  // GoogleMap.vue
  GM_ERROR_CODE: {}
};

/**
 * firestoreから病院の空床情報やPR情報を取得する
 * /hospitalPrCollection/{kintone_id}/
 */
import { collection, doc, getDocs, limit, orderBy, query } from 'firebase/firestore/lite';
import { HospitalPrType } from 'types/GetFirebaseDataTypes';
import { db } from '~/components/settings/firebase';

const TARGET_COLLECTION_NAME = 'hospitalPRCollection';
const SUB_COLLECTION_NAME = 'historyCollection';

export default async function (clientId: string) {
  try {
    if (clientId) {
      const docRef = doc(db, TARGET_COLLECTION_NAME, clientId);
      const q = query(
        collection(docRef, SUB_COLLECTION_NAME),
        orderBy('updateAt', 'desc'),
        limit(1)
      );

      const querySnapshot = await getDocs(q);
      let ret: HospitalPrType | {} = {};
      querySnapshot.forEach((doc) => {
        ret = doc.data() as HospitalPrType;
      });
      return ret;
    }
  } catch (error) {
    // 何もしない
  }
}
